<!--
 * @Author: your name
 * @Date: 2021-07-27 16:03:10
 * @LastEditTime: 2021-10-24 09:10:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-growing/src/views/About.vue
-->
<template>
  <v-app>
    <v-container grid-list-xs>
      <v-card>
        <v-card-title class="primary white--text" dark primary-title>
          安全登录验证
        </v-card-title>
        <v-card-text class="subtitle-1 py-3">
          你正在尝试微信验证完成网页登录操作
        </v-card-text>
        <v-card-text class="d-flex justify-center py-10">
          <v-btn elevation="0" width="150" height="150" fab color="primary">
            <v-icon style="font-size: 60px">mdi-lock</v-icon>
          </v-btn>
        </v-card-text>
        <v-card-actions v-if="wxopenid" class="d-flex justify-center py-5">
          <div v-if="wxopenid.uid">
            <v-btn
              rounded
              class="px-3"
              color="success"
              :disabled="state"
              @click="loginForm(1)"
            >
              <v-icon class="mr-1">mdi-check</v-icon>
              确认登陆
            </v-btn>
          </div>
          <div v-else class="text-h6">
            <div class="py-2">你的微信账号未与系统关联</div>
            <v-btn rounded class="px-3" color="primary" @click="goLogin">
              我要登录、注册
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
      <!--
      <h3>This is an oauth2 page</h3>
      <h6>wechat-url:{{ url }}</h6>
      <h6>code:{{ code }}</h6>
      <h6>wechat:{{ wechat }}</h6>
      <h6>urls:{{ urls }}</h6>
      <h6>sid:{{ wechat.sid }}</h6>
      <h6>openid:{{ wechat.openid }}</h6>
      <h6>wxopenid:{{ wxopenid }}</h6>
      <h6>loginsys:{{ loginsys }}</h6>
      <h6>weChatOpenid:{{ weChatOpenid }}</h6>
      <div>
        <vue-qr ref="Qrcode" :text="urlsCode" qid="testQrId"></vue-qr>
      </div>
      -->
    </v-container>
  </v-app>
</template>
<script>
//import VueQr from "vue-qr";

export default {
  //components: { VueQr },
  data() {
    return {
      url: "",
      urls: "",
      op: "1008",
      code: "",
      scode: "",
      urlsCode: "",

      wechat: {
        openid: "",
        sid: "",
      },
      wxopenid: "",
      loginsys: "",
      weChatOpenid: "",
      state: false,
    };
  },
  created() {
    this.urlsCode = window.location.href;
    this.urls = this.$route.query;
    this.op = this.$route.query.OP;
    this.code = this.$route.query.code;
  },
  mounted() {
    this.uscode();
  },
  methods: {
    goLogin() {
      this.$router.push({ path: "/login" });
    },
    uscode() {
      var data = {
        module: "wechat",
        title: "uscode",
        data: {
          appid: this.op,
          code: this.code,
        },
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then((res) => {
        if (res.data.wechat) {
          if (res.data.wechat && res.data.wechat.openid) {
            that.wechat = res.data.wechat;
            let cookieOpenid = {};
            cookieOpenid[that.wechat.sid] = that.wechat.openid;
            that.weChatOpenid = JSON.stringify(cookieOpenid);
            localStorage.setItem("weChatOpenid", that.weChatOpenid);
            that.wechatLogin();
          }
        }
      });
    },
    wechatLogin() {
      var data = {
        module: "user",
        title: "wxopenid",
        data: {
          appid: this.wechat.sid,
          openid: this.wechat.openid,
          aid: 0,
        },
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then((res) => {
        that.wxopenid = res.data.user;
        if (that.wxopenid && that.wxopenid.uid) {
          //return false;
          that.loginForm();
        }
      });
    },
    loginForm(login = 0) {
      let u = this.$route.query.u;
      var data = {
        module: "loginsys",
        title: "loginwx",
        data: {
          unid: u,
          openid: this.wechat.openid,
          confirm: login,
        },
      };
      if (login == 1) this.state = true;
      //let that = this;
      this.$sopen.requestUscenterApi(data).then(() => {});
    },
    reusrec(targetid = "") {
      let tokenValue = this.$sopen.getCookie("usCookie");
      let data = {
        module: "usinfo",
        title: "reusrec",
        data: {
          cookie: tokenValue,
          targetid: targetid,
          url:
            document.location.protocol +
            "//" +
            (window.location.host.indexOf("schoolopen") > -1
              ? window.location.host
              : "luohu.sopen.cn"),
        },
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        if (res.data.usinfo.usinfo.TARGETID != "") {
          that.$router.push({
            path: "/token",
            query: {
              openuid: res.data.usinfo.openuid,
              state: encodeURIComponent(that.state),
            },
          });
        } else {
          that.$router.push({
            path: "/user",
          });
        }
      });
    },
  },
};
</script>
